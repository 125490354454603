import React from "react"
import PropTypes from "prop-types"

import "animate.css/animate.css"
import "./layout.scss"

const Layout = ({ children }) => {
  return <main className="layoutMain">{children}</main>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
